import httpRequest from '@/utils/network/request';

// 灰度设置列表
export const grayList = function (params = {}) {
  const url = '/gray/list';
  return httpRequest.get(url, params);
};

// 灰度设置增加
export const grayCreation = function (params = {}) {
  const url = '/gray/creation';
  return httpRequest.post(url, params);
};

// 灰度设置修改
export const grayModification = function (params = {}) {
  const url = '/gray/modification';
  return httpRequest.post(url, params);
};