<template>
  <div>
    <transition name="el-zoom-in-center">
      <el-table v-if="showTable"
        :data="list"
        stripe
        border
        @row-dblclick="rowDbclick"
        style="width: 100%">
        <el-table-column
          prop="api_path"
          label="接口名"
          width="180">
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="100">
        </el-table-column>
        <el-table-column
          prop="info"
          label="描述"
          width="300">
        </el-table-column>
        <el-table-column
          prop="deviceStr"
          label="适用平台"
          width="100">
        </el-table-column>
        <el-table-column
          prop="tail_love_space_id_start"
          label="空间ID区段开始"
          width="100">
        </el-table-column>
        <el-table-column
          prop="tail_love_space_id_end"
          label="空间ID区段结束"
          width="100">
        </el-table-column>
        <el-table-column
          prop="white_list_space_ids"
          label="白名单"
          width="180">
        </el-table-column>
        <el-table-column
          prop="extension"
          label="扩展字段"
          width="120">
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="上次修改时间"
          width="100">
        </el-table-column>
        <el-table-column
          prop="update_user_name"
          label="上次修改人">
        </el-table-column>
      </el-table>
    </transition>
    <el-dialog title="功能入口配置" :visible.sync="showForm">
        <el-form class="form" ref="form" :model="interfaceDetail" label-width="180px">
            <el-form-item label="接口名：">
                <el-input v-model="interfaceDetail.api" :disabled="!interfaceDetail.isAdd" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="名称：">
                <el-input v-model="interfaceDetail.name" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="描述：">
                <el-input v-model="interfaceDetail.info" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="适用平台：">
                <el-checkbox-group v-model="platformList">
                  <el-checkbox label="安卓"></el-checkbox>
                  <el-checkbox label="iOS"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="空间ID区段开始：">
                <el-input v-model="interfaceDetail.start" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="空间ID区段结束：">
                <el-input v-model="interfaceDetail.end" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="白名单：">
                <el-input v-model="interfaceDetail.white_list_space_ids" placeholder="空间ID以英文逗号隔开" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item label="扩展字段：">
                <el-input v-model="interfaceDetail.extension" placeholder="json（可为空）" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{interfaceDetail.isAdd ? '创建':'立即修改'}}</el-button>
                <el-button @click="cancelSubmit">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-button
        class="add-btn"
        type="primary"
        icon="el-icon-plus"
        circle
        @click="addBtnClick">
    </el-button>
  </div>
</template>

<script>
import {grayList, grayCreation, grayModification} from '@/api/gray.js';
import httpRequest from '@/utils/network/request';
export default {
  name: 'History',
  props: {

  },
  data () {
    return {
      showTable: false,
      showForm: false,
      list: [],
      interfaceDetail: {
      },
      platformList: []
    };
  },

  methods: {
    rowDbclick (row) {
      console.log('双击了某一行', row);
      this.showForm = true;
      this.interfaceDetail = {
        isAdd: false,
        id: row.id,
        api: row.api_path,
        name: row.name,
        info: row.info,
        start: row.tail_love_space_id_start,
        end: row.tail_love_space_id_end,
        white_list_space_ids: row.white_list_space_ids,
        extension: row.extension
      };
      let platformList = [];
      if (row.device_android == 1) {
        platformList.push('安卓');
      }
      if (row.device_ios == 1) {
        platformList.push('iOS');
      }
      this.platformList = platformList;
    },

    addBtnClick () {
      this.showForm = true;
      this.interfaceDetail = {
        isAdd: true,
        api: '',
        name: '',
        info: '',
        start: '',
        end: ''
      };
      this.platformList = [];
    },

    onSubmit () {
      let that = this;
      that.showForm = false;
      let interfaceDetail = this.interfaceDetail;
      let param = {
        api_path: interfaceDetail.api,
        name: interfaceDetail.name,
        info: interfaceDetail.info,
        tail_love_space_id_start: interfaceDetail.start,
        tail_love_space_id_end: interfaceDetail.end,
        device_android: this.platformList.indexOf('安卓') > -1 ? '1' : '0',
        device_ios: this.platformList.indexOf('iOS') > -1 ? '1' : '0',
        white_list_space_ids: interfaceDetail.white_list_space_ids,
        extension: interfaceDetail.extension
      };
      if (interfaceDetail.isAdd) {
        // 新加接口配置
        grayCreation(param)
          .then((res) => {
            console.log('新加接口配置成功：', res);
            that.getDataList();
          })
          .catch((error) => {
            console.log('新加接口配置失败：', error);
          });
      } else {
        // 编辑接口配置
        param.gray_config_id = interfaceDetail.id;
        grayModification(param)
          .then((res) => {
            console.log('编辑接口配置成功：', res);
            that.getDataList();
          })
          .catch((error) => {
            console.log('编辑接口配置失败：', error);
          });
      }

    },

    cancelSubmit () {
      this.showForm = false;
    },

    getDataList () {
      let that = this;
      grayList()
        .then((res) => {
          console.log('灰度列表记录：', res);
          let list = res.data;
          list.forEach((item) => {
            let deviceStr = '';
            if (item.device_android == 1) {
              deviceStr += '安卓';
            }
            if (item.device_ios == 1) {
              deviceStr += ' iOS';
            }
            item.deviceStr = deviceStr;
          });
          that.list = list;
          that.showTable = true;
        })
        .catch((error) => {
          console.log('灰度列表记录失败：', error);
        });
    }
  },

  beforeCreate () {},
  created () {
    this.getDataList();
    // httpRequest.post('http://123.206.57.180:8092/console/query/new/api', {key: 1, platform: 0})
    //   .then((res) => {
    //     console.log('11111123141242', res);
    //   })
    //   .catch((error) => {
    //     console.log('77777777777', error);
    //   });
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.add-btn{
        position: fixed;
        width: 60px;
        height: 60px;
        right: 50px;
        bottom: 50px;
    }

</style>
